import React from "react";
import "../../i18n";
import { useTranslation } from 'react-i18next';
import { Announcement } from "@civicactions/data-catalog-components";
import Layout from '../../components/Layout';
import config from "../../assets/config";

function Heading() {
    const { t, i18n } = useTranslation();
    return (
          <h1>{t('project_agency')}</h1>
    );
  }

const About = ({ path }) => (
    <Layout title="About">
        <div className={`dc-page ${config.container}`}>
            <Heading/>
            <div className="dc-page-content row">
                <div class="col-md-9 col-sm-12">

                <p>Το νομικό πρόσωπο δημοσίου δικαίου με την επωνυμία «Ελληνικό Κτηματολόγιο», ιδρύθηκε με το ν. 4512/2018 και εποπτεύεται σήμερα από το Υπουργείο Ψηφιακής Διακυβέρνησης. Σκοπός του ΝΠΔΔ «Ελληνικό Κτηματολόγιο» είναι η διασφάλιση της αξιοπιστίας, δημοσιότητας και διαθεσιμότητας των χωρικών και νομικών δεδομένων που αφορούν την ακίνητη ιδιοκτησία και η διασφάλιση της δημόσιας πίστης και ασφάλειας των συναλλαγών, σε σχέση με τα δεδομένα αυτά.Ο σκοπός αυτός επιτυγχάνεται με την καταχώριση νομικών και τεχνικών πληροφοριών, για τον ακριβή καθορισμό της θέσης και των ορίων των ακινήτων και τη δημοσιότητα των εγγραπτέων δικαιωμάτων και βαρών μέσω της σύνταξης, τήρησης, ενημέρωσης και λειτουργίας του Εθνικού Κτηματολογίου.Στο σκοπό του επίσης περιλαμβάνεται και η γεωδαιτική και χαρτογραφική κάλυψη της χώρας, καθώς και η δημιουργία και τήρηση ψηφιακών γεωχωρικών δεδομένων.</p>

                <p>Το ΝΠΔΔ «Ελληνικό Κτηματολόγιο» είναι αρμόδιο για:</p>

                <ul>
                <li>Την σύνταξη, τήρηση, ενημέρωση και λειτουργία του Εθνικού Κτηματολογίου.</li>
                <li>Την τήρηση, ενημέρωση και λειτουργία του συστήματος των υποθηκοφυλακείων στα Κτηματολογικά Γραφεία και τα Υποκαταστήματα του Φορέα, τα οποία συστήνονται σταδιακά με την απορρόφηση των υποθηκοφυλακείων από τον Φορέα.</li>
                <li>Την σύνταξη, ενημέρωση, τήρηση και αναθεώρηση βασικών και παράγωγων τοπογραφικών χαρτών και τοπογραφικών διαγραμμάτων.</li>
                <li>Τον σχεδιασμό, την ανάπτυξη, την οργάνωση, τη λειτουργία και την διαχείριση συστημάτων αναγκαίων για τη δημιουργία και διαχείριση γεωδαιτικού υλικού που σχετίζονται με το σκοπό του.</li>
                <li>Τον προγραμματισμό, την εκτέλεση και τον έλεγχο φωτογραμμετρικών και τηλεπισκοπικών εργασιών από τη λήψη αεροφωτογραφιών και δορυφορικών εικόνων έως και την τελική απόδοση, για την κάλυψη των αναγκών του Φορέα, του Ελληνικού Δημοσίου, των Οργανισμών Τοπικής Αυτοδιοίκησης και των Νομικών Προσώπων Δημοσίου Δικαίου.</li>
                <li>Την οργάνωση και τήρηση βάσεων ψηφιακών γεωχωρικών δεδομένων.​</li>
                </ul>

                {/* <h2><a href="/project">Το έργο</a></h2>
                <h2><a href="/opendata">Ανοιχτά Δεδομένα Ελληνικού Κτηματολογίου</a></h2> */}
 
                <p></p>
                </div>
                <div className="col-md-3 col-sm-12">
                    <Announcement variation="info" heading="">
                    <p><a href="https://www.ktimatologio.gr/photo/medium/202012/zz2moq1607000881211.pdf" target="_blank">Απόφαση του ΔΣ του Ελληνικού Κτηματολογίου για την διάθεση Ανοιχτών Δεδομένων</a></p>
                    <p><a href="https://www.ktimatologio.gr/photo/medium/202012/hkq36j1607001500322.XLSX" target="_blank">Πίνακας Ανοιχτών Δεδομένων του Ελληνικού Κτηματολογίου</a></p>
                    </Announcement>
                </div>
            </div>
        </div>
    </Layout>
);

export default About;
